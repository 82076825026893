<template>
  <ag-card-container
    :search.sync="search"
    title="Liste des types de relevés"
    fluid
    no-content-padding
  >
    <template #left>
      <v-btn
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
    </template>

    <record-type-list
      :value="recordTypeList"
      :selected.sync="selectedRecordTypeList"
      :loading="loadingData"
      :search="search"
    >
      <template #footer>
        <v-btn
          color="accent"
          outlined
          small
          append
          to="restore"
        >
          éléments supprimés
        </v-btn>
      </template>
    </record-type-list>

    <ag-float-button-container>
      <ag-confirm-dialog
        v-if="selectedRecordTypeList.length"
        title="Confirmation"
        :message="removeMessage"
        cancel-label="Annuler"
        confirm-label="Supprimer"
        validation-btn-color="error"
        @confirm="removeSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRemove"
            color="error"
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>delete</v-icon>
            <v-chip
              x-small
              class="px-1 float-chip font-weight-black"
              color="white"
              text-color="error"
              v-text="selectedRecordTypeList.length"
            />
          </v-btn>
        </template>
      </ag-confirm-dialog>
      <v-btn
        to="create"
        append
        color="accent"
        fab
      >
        <v-icon>add</v-icon>
      </v-btn>
    </ag-float-button-container>

    <!-- RouterView for modals -->
    <router-view />
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import RecordTypeList from '../components/RecordTypeList'

export default {
  name: 'RecordTypeListContainer',
  components: {
    AgCardContainer,
    AgFloatButtonContainer,
    AgConfirmDialog,
    RecordTypeList,
  },
  data: () => ({
    recordTypeList: [],
    selectedRecordTypeList: [],
    search: '',
    loadingData: false,
    loadingRemove: false,
    loadingExport: false,
  }),
  computed: {
    removeMessage () {
      const count = this.selectedRecordTypeList.length
      return `Souhaitez-vous supprimer ${count} type(s) de relevé(s)`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'RecordTypeList' || !this.recordTypeList.length) {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData () {
      try {
        this.loadingData = true
        this.recordTypeList = await Repositories.recordType.getList({ archived: false })
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async removeSelected () {
      try {
        this.loadingRemove = true
        const count = this.selectedRecordTypeList.length
        await Repositories.recordType.delete(
          this.selectedRecordTypeList.map(select => select._id),
        )
        this.showSuccessNotification(`Vous avez supprimé ${count} type(s) relevé(s)`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRemove = false
        this.selectedRecordTypeList = []
        this.fetchData()
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const exportConfig = {
          columns: [
            { header: 'Nom', key: 'name' },
            { header: 'Description', key: 'description' },
            { header: 'Type de référence', key: 'partType' },
            { header: 'Type de relevé', key: 'level' },
            { header: 'Engendre la desctruction de la pièce', key: 'destroy' },
            { header: 'Relevé non obligatoire', key: 'sampling' },
            { header: 'Type de valeur', key: 'type' },
            { header: 'Mask', key: 'mask' },
            { header: 'Min', key: 'min' },
            { header: 'Max', key: 'max' },
            { header: 'Id', key: '_id' },
          ],
          view: [{ state: 'frozen', xSplit: 1, ySplit: 1 }],
          sheetName: 'Liste des types de relevés',
        }
        const data = this.recordTypeList.map(item => ({
          _id: item._id,
          name: item.name,
          description: item.description,
          partType: item.partType.name,
          level: item.level,
          destroy: item.destroy,
          sampling: item.sampling,
          type: item.type,
          mask: item.mask,
          min: item.min ? parseFloat(item.min) : '',
          max: item.max ? parseFloat(item.max) : '',
        }))
        await createExcelFile([{ dataToExport: data, config: exportConfig }], 'Liste des types de relevés')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
